<template>
    <div :style="$route.name!='project_schedule'? 'cursor: pointer':'cursor: default'"
        class="banner ma-0 red darken-2 pl-3 " v-on:click="$route.name!='project_schedule'? $router.push({
                name: 'project_schedule',
                query: { id: $route.params.projectId },
            }):null">
        <v-row class="ma-0 mt-0" align="center" justify="start" style="height: 48px!important">
            <v-icon class="mr-2" color="white" size="24">mdi-alert-circle</v-icon>

            <span>{{$t('tools.project.arming.banner.armed')}}</span>
            <span v-if="$store.getters.noDevices===true" class="ml-2">({{$t('tools.project.arming.banner.noDevices')
            }})</span>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'BannerScheduleStatus',
};
</script>

<style scope>
.banner {
    position: relative;
    height: 48px !important;
    min-height: 48px;
    color: white;
    cursor: pointer;
    width: 100%;
    z-index: 9999;
}
</style>
