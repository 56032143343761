<template>
  <div>
    <v-list-item
      v-on:click="forward()"
      :style="
        selected == index
          ? 'pointer-events: none; cursor: pointer!important'
          : disabled
          ? 'opacity: .3; pointer-events: none; background-color: rgba(220, 220, 220, .1)'
          : ''
      "
      dense
      color="black"
      class="mt-1"
      :ripple="false"
      style="margin-bottom: 0px"
    >
      <v-list-item-icon
        :class="selected == index ? `primary--text` : ''"
        class="ml-2"
        style="margin-right: 12px !important"
        transition=""
      >
        <v-icon size="19">{{ icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title
          :class="
            selected == index
              ? `font-weight-bold primary--text`
              : 'font-weight-normal'
          "
          style="font-size: 15px; padding-bottom: 1px"
        >
          <v-row class="ma-0" align="center">
            {{ name }}<v-spacer />
            <v-icon
              v-if="children != null"
              :style="selected == index ? 'transform: rotate(180deg)' : ''"
              size="18"
              >mdi-chevron-down</v-icon
            >
          </v-row>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div v-if="children != null && children.length > 0">
      <v-expand-transition>
        <div
          v-show="selected == index && children != null && children.length > 0"
          style="width: 200px; position: relative"
          class="black--text"
        >
          <div
            :style="`height: ${children.length * 28 - 5}px`"
            style="
              position: absolute;
              top: 8px;
              left: 45px;
              width: 4px;
              border-radius: 2px;
            "
            class="primary"
          />
          <div
            class="pt-2"
            style="padding-left: 60px; font-size: 14px !important"
          >
            <div
              style="
                cursor: pointer;
                min-height: 28px;
                min-height: 28px;
                height: 28px;
              "
              :style="selectedSubpage == i ? 'pointer-events: none' : ''"
              v-for="(item, i) in children"
              :key="i"
            >
              <div
                v-on:click="forwardSubpage(i)"
                :class="
                  selectedSubpage == i
                    ? 'primary--text font-weight-bold'
                    : 'black--text'
                "
              >
                {{ $t(`${item.meta.i18n}.title`) }}
              </div>
            </div>
          </div>
          <!-- <v-divider class="ml-4 mt-3"/> -->
          <div style="width: 200px; height: 10px" />
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListElement',
  props: {
    alt: {
      type: String,
      default: null,
    },
    route: {
      type: String,
    },
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: [Number, String],
      required: true,
    },
    index: {
      type: [String, Number],
      required: true,
    },
    children: {
      type: Array,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedSubpage: 0,
    };
  },

  watch: {
    selected() {
      if (this.selected != this.index) this.selectedSubpage = 0;
    },
  },

  created() {
    if (this.selected == this.index && this.children != null) {
      var selectedSub = this.children.findIndex(
        (i) => i.name == this.$route.name,
      );
      if (selectedSub == -1) this.$helper.forward(this.alt);

      this.selectedSubpage = selectedSub;
    }
  },

  methods: {
    forward() {
      if (!this.children || this.children == null || this.children.length == 0)
        return this.$helper.forward(this.route);
      else
        return this.$helper.forward(this.children[this.selectedSubpage].name);
    },

    forwardSubpage(index) {
      this.selectedSubpage = index;
      return this.$helper.forward(this.children[this.selectedSubpage].name);
    },
  },
};
</script>
