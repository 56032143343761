var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{staticClass:"mt-1",staticStyle:{"margin-bottom":"0px"},style:(_vm.selected == _vm.index
        ? 'pointer-events: none; cursor: pointer!important'
        : _vm.disabled
        ? 'opacity: .3; pointer-events: none; background-color: rgba(220, 220, 220, .1)'
        : ''),attrs:{"dense":"","color":"black","ripple":false},on:{"click":function($event){return _vm.forward()}}},[_c('v-list-item-icon',{staticClass:"ml-2",class:_vm.selected == _vm.index ? "primary--text" : '',staticStyle:{"margin-right":"12px !important"},attrs:{"transition":""}},[_c('v-icon',{attrs:{"size":"19"}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.selected == _vm.index
            ? "font-weight-bold primary--text"
            : 'font-weight-normal',staticStyle:{"font-size":"15px","padding-bottom":"1px"}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.name)),_c('v-spacer'),(_vm.children != null)?_c('v-icon',{style:(_vm.selected == _vm.index ? 'transform: rotate(180deg)' : ''),attrs:{"size":"18"}},[_vm._v("mdi-chevron-down")]):_vm._e()],1)],1)],1)],1),(_vm.children != null && _vm.children.length > 0)?_c('div',[_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected == _vm.index && _vm.children != null && _vm.children.length > 0),expression:"selected == index && children != null && children.length > 0"}],staticClass:"black--text",staticStyle:{"width":"200px","position":"relative"}},[_c('div',{staticClass:"primary",staticStyle:{"position":"absolute","top":"8px","left":"45px","width":"4px","border-radius":"2px"},style:(("height: " + (_vm.children.length * 28 - 5) + "px"))}),_c('div',{staticClass:"pt-2",staticStyle:{"padding-left":"60px","font-size":"14px !important"}},_vm._l((_vm.children),function(item,i){return _c('div',{key:i,staticStyle:{"cursor":"pointer","min-height":"28px","height":"28px"},style:(_vm.selectedSubpage == i ? 'pointer-events: none' : '')},[_c('div',{class:_vm.selectedSubpage == i
                  ? 'primary--text font-weight-bold'
                  : 'black--text',on:{"click":function($event){return _vm.forwardSubpage(i)}}},[_vm._v(" "+_vm._s(_vm.$t(((item.meta.i18n) + ".title")))+" ")])])}),0),_c('div',{staticStyle:{"width":"200px","height":"10px"}})])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }