<template>
    <div>
        <!-- Loading Failure -->
        <div v-if="isProjectLoadingFailed==true||isFailed==true" class="center">
            <NoDataTemplate text="Fehler beim Laden" icon="mdi-connection" />
        </div>

        <div v-else-if="loadingProject==true" class="splash-screen" oncontextmenu="return false">
            <!-- Loading AlinoCam screemn -->
            <div class="center" style="pointer-events: none; cursor: default !important">
                <v-col align="center">
                    <img class="loading-img" width="300" preload src="@/assets/logo/alinocam/text_large.webp" />
                    <br />
                    <img preload height="30" width="30" src="@/assets/loading.gif" />
                </v-col>
            </div>

            <!-- Go back to project overview button -->
            <div class="mt-3 ml-4">
                <TextButton v-on:click="$router.push({ name: 'project_overview' })" dense icon="mdi-chevron-left"
                    :text="$t('tools.project.page.loading.button.return-overview')" />
            </div>
        </div>

        <div v-else-if="project!=null&&project.general!=null&&!loadingProject">
            <div style="user-select: none; overflow: hidden">
                <v-navigation-drawer :touchless="true" absolute left class="white drawer elevation-1" v-model="drawer"
                    width="240px" :permanent="$vuetify.breakpoint.mdAndUp">
                    <!-- AlinoCam side-bar logo; on click to user landing page -->
                    <div style="position: relative" v-on:click="$router.push($auth.landingPage())">
                        <v-row class="ma-0 ml-4 pb-2 mt-2" align="center">
                            <img class="side-bar-img" :width="210" src="@/assets/logo/alinocam/text_medium.png" />
                        </v-row>
                    </div>

                    <!-- Project information; only displayed if valid project (== not loading) -->
                    <div v-if="project!=null&&project.general!=null" class="ml-0 mr-2 mt-2">
                        <span class="pl-4" style="font-size: 13px">{{$t('tools.project.page.sideBar.project')}}:
                        </span>
                        <br />
                        <!-- Project quick access only shown if user has multiple projects -->
                        <div v-if="$store.getters.user!=null&&
                            $store.getters.user.projects!=null
                            " :style="$store.getters.user!=null&&
            ($store.getters.user.projects==null||
                $store.getters.user.projects.length==1)
            ? 'cursor: default!important'
            :'cursor: pointer!important'
        ">
                            <v-menu transition="none" :close-on-content-click="false" :disabled="$store.getters.user.projects==null||
                                $store.getters.user.projects.length==1
                                " v-model="projectSelector" offset-x nudge-top="3" nudge-right="10" width="250">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-on="on" v-bind="attrs">
                                        <v-tooltip open-delay="1000" right :disabled="projectSelector">
                                            <template v-slot:activator="{ on, attrs }">
                                                <!-- shadow and rotating arrow only displayed if multiple projects in user object -->
                                                <div v-bind="attrs" v-on="on" class="project-selector" :style="projectSelector==true
                                                        ? 'background-color: rgba(240, 240, 240, .6)!important'
                                                        :$store.getters.user.projects==null||
                                                            $store.getters.user.projects.length==0
                                                            ? 'pointer-events: none; cursor: default!important'
                                                            :'cursor: pointer!important'
                                                    ">
                                                    <v-row class="ma-0" align="center" :style="$store.getters.user.projects!=null&&
                                                            $store.getters.user.projects.length>0
                                                            ? 'cursor: pointer!important'
                                                            :'cursor: default!important; pointer-events: none!important'
                                                        ">
                                                        <!-- project title is shortend depending on user projects length -->
                                                        <div class="pl-4 font-weight-bold"
                                                            style="margin-top: 5px; font-size: 16px">
                                                            {{
                                                                $helper.shortenText(
                                                                    project.general.name,
                                                                    $store.getters.user.projects==null||
                                                                        $store.getters.user.projects.length==0
                                                                        ? 27
                                                                        :19,
                                                                )
                                                            }}
                                                        </div>

                                                        <v-spacer />
                                                        <!-- arrow rotates 90° if menu ($projectSelector) is active -->
                                                        <v-icon v-if="$store.getters.user.projects!=null&&
                                                                $store.getters.user.projects.length>1
                                                                " :style="projectSelector==true
                    ? 'transform: rotate(-90deg); color: var(--v-primary-base)!important'
                    :''
                " size="18" class="mr-4 mt-2">
                                                            mdi-chevron-down
                                                        </v-icon>

                                                        <IconButton class="mr-2 mt-1" v-else v-on:click="$router.push({ name: 'project_overview' })
                                                                " v-show="$store.getters.user.projects!=null&&
                $store.getters.user.projects.length>0
                " dense icon="mdi-arrow-right" />
                                                    </v-row>
                                                </div>
                                            </template>

                                            <!-- Tooltip content -->
                                            <div>
                                                <b>{{$t('tools.project.page.sideBar.tooltipName')}}:
                                                </b>
                                                <span class="ml-1">{{project.general.name}}</span>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <!-- Menu Content (project selector list) -->
                                <div style="background-color: white !important" class="pb-2">
                                    <!-- Title row with icon link to project overview page -->
                                    <v-row class="ma-0 ml-3 mr-3 pt-2" align="center">
                                        <span class="font-weight-bold" style="font-size: 15px">{{
                                            $t('tools.project.page.sideBar.projectSelector.title')
                                        }}</span>
                                        <v-spacer />
                                        <IconButton v-on:click="$router.push({ name: 'project_overview' })" v-show="$store.getters.user.projects!=null&&
                                                $store.getters.user.projects.length>0
                                                " dense icon="mdi-arrow-right" />
                                    </v-row>

                                    <v-divider class="mr-2 ml-2 mt-2" />

                                    <!-- project selctor list -->
                                    <v-list class="list-projects" width="250" v-if="$store.getters.user.projects!=null" nav
                                        height="160">
                                        <v-list-item dense style="height: 32px" :ripple="false" v-for="(item, index) in $store.getters.user.projects
                                                .filter(
                                                    (project) => project.id!=$route.params.projectId,
                                                )
                                                .sort(
                                                    (a, b) => Number(a.archived>b.archived)*2-1,
                                                )" :key="index" v-on:click="openProject(item.id)">
                                            <v-list-item-title class="ml-1" style="font-size: 14px">
                                                {{item.name}}</v-list-item-title>

                                            <v-list-item-action>
                                                <v-icon size="16" v-show="item.archived">mdi-archive</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-menu>
                        </div>
                    </div>

                    <!-- divider between project title and list menu -->
                    <v-divider class="ml-3 mr-3 mt-4" />

                    <!-- List menu -->
                    <ListNavigation v-show="!isProjectLoading" style="overflow-x: hidden !important"
                        v-on:error="errorNavigationEvent" class="mt-0 pt-2" />

                    <!-- bottom navigation (weather + logout button) -->
                    <div style="
                                      position: absolute;
                                      bottom: 10px;
                                      width: 100%;
                                      background-color: transparent;
                                    ">
                        <!-- <v-divider class="ml-3 mr-3" /> -->

                        <!-- INDICATOR STATUS CHIP (BUTTON) -->
                        <div class="chip-btn">
                            <v-row v-if="showStatusChip" id="indicator_status" class="ma-0" align="center" justify="center">
                                <TextButton style="width: 100%; " v-on:click="$route.name!='project_schedule'?
                                        $router.push({
                                            name: 'project_schedule',
                                            query: { id: $route.params.projectId },
                                        }):''
                                    " dense :class="[
            isRedFont
                ? 'btn-indicator-status-red'
                :'btn-indicator-status',
        ]" outerIcon="mdi-chevron-right" :text="`${getScheduleStatus}`" />
                            </v-row>

                            <!-- INTERNAL SETTING CHIP (BUTTON) -->
                            <v-row v-if="$store.getters.user.internal" id="internal_setting" class="ma-0" align="center"
                                justify="center">
                                <TextButton v-on:click="openAdmin($route.params.projectId)
                                    " buttonColor="primary" dense icon="mdi-tune-variant"
                                    :text="$t('tools.project.page.sideBar.button.internal')" />
                            </v-row>
                        </div>

                        <v-divider class="ml-3 mr-3 mb-3 mt-2" />

                        <v-row class="ma-0 ml-2" align="center">
                            <TextButton dense icon="mdi-chevron-left" :text="$t('tools.project.page.sideBar.button.logout')"
                                v-on:click="$router.push({ name: 'login' })" />

                            <v-menu v-model="languageSelector" transition="none" :close-on-content-click="false" offset-x
                                nudge-right="17" nudge-top="20">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-on="on" v-bind="attrs" class="country-selector-icon">
                                        <v-overlay :opacity="0.65" :value="languageSelector" absolute color="white">
                                            <v-icon size="17" style="margin-right: 10px" class="mb-1"
                                                color="black">mdi-chevron-right</v-icon>
                                        </v-overlay>
                                        <country-flag style="border-radius: 10px" :country="$route.params.lang=='en'? 'gb':$route.params.lang
                                            " size="normal"></country-flag>
                                    </div>
                                </template>
                                <div class="elevation-1 pa-2" style="
                                            height: 110px;
                                            width: 200px;
                                            border-radius: 10px;
                                            background-color: white;
                                            overflow: hidden !important;
                                          ">
                                    <div class="mb-2 ml-1 grey--text font-weight-bold"
                                        style="user-select: none !important; font-size: 15px">
                                        {{
                                            $t(
                                                'tools.project.page.sideBar.languageSelector.chooseLanguage',
                                            )
                                        }}:
                                    </div>
                                    <div v-on:click="switchLanguage('de')" class="language-selector-list-item" :style="$route.params.lang=='de'
                                                ? 'background-color: #F1DEDD; pointer-events: none; cursor: default'
                                                :'cursor: pointer!important;'
                                            ">
                                        <country-flag class="flag-style" country="de" size="normal" />
                                        <div :class="$route.params.lang=='de'
                                                    ? 'primary--text font-weight-bold'
                                                    :''
                                                " style="
                                                padding-top: 4px;
                                                padding-left: 50px;
                                                font-size: 14px;
                                              ">
                                            {{$t('tools.project.page.sideBar.languageSelector.de')}}
                                        </div>
                                    </div>

                                    <div v-on:click="switchLanguage('en')" class="language-selector-list-item mt-1" :style="$route.params.lang=='en'
                                        ? 'background-color: #F1DEDD; pointer-events: none; cursor: default'
                                        :'cursor: pointer!important;'
                                        ">
                                        <country-flag class="flag-style" country="gb" size="normal" />
                                        <div :class="$route.params.lang=='en'
                                                ? 'primary--text font-weight-bold'
                                                :''
                                            " style="
                                                padding-top: 4px;
                                                padding-left: 50px;
                                                font-size: 14px;
                                              ">
                                            {{$t('tools.project.page.sideBar.languageSelector.en')}}
                                        </div>
                                    </div>
                                </div>
                            </v-menu>
                        </v-row>
                    </div>
                </v-navigation-drawer>

                <div :style="$vuetify.breakpoint.mdAndUp
                    ? 'left: 240px; width: calc(100vw - 240px);'
                    :'left: 0px; width: 100vw'
                    " style="
                                    position: absolute;
                                    left: 240px;
                                    height: 100vh !important;
                                    background-color: transparent;
                                  ">

                    <div class="nav">
                        <!-- <v-fab-transition>
                            <v-btn v-show="!$vuetify.breakpoint.mdAndUp" color="primary" style="z-index: 90" fab dark
                                :ripple="false" small absolute bottom left class="mb-8 elevation-0"
                                @click.stop="drawer=!drawer">
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                        </v-fab-transition> -->

                        <!-- ---------------------------------------------------- -->
                        <!-- SHOW PROJECT VIEWS TO THE RIGHT OF THE SCREEN -->
                        <!-- ---------------------------------------------------- -->
                        <!-- -->
                        <div>
                            <BannerScheduleStatus v-if="isShowBanner" class="nav-header" />
                        </div>
                        <div class="nav-body" :style="isShowBanner? 'height: calc(100% - 48px)!important':''">


                            <router-view />
                        </div>
                        <!-- ---------------------------------------------------- -->
                    </div>
                </div>
            </div>
        </div>
        <!--   -->
    </div>
</template>

<script>
import ListNavigation from '@/components/navigation/ListNavigation.vue';
import TextButton from '@components/button/TextButton.vue';
import CountryFlag from 'vue-country-flag';
import NoDataTemplate from '@components/page/NoDataTemplate.vue';
import IconButton from '@components/button/IconButton.vue';
import BannerScheduleStatus from '@/components/tools/project/BannerScheduleStatus.vue';

import { scheduleStatusEnum } from '@/components/tools/project/schedule/status';

export default {
    name: 'ProjectViewNavigation',
    components: {
        NoDataTemplate,
        ListNavigation,
        TextButton,
        CountryFlag,
        IconButton,
        BannerScheduleStatus,
    },
    data() {
        return {
            userProjects: {
                loading: true,
                data: null,
            },
            languageSelector: false,
            projectSelector: false,
            loadingProject: true,
            isFailed: false,
            drawer: false,
            hiddenStart: null,
            // schedule status
            scheduleStatus: '',
            showStatusChip: true,
        };
    },

    watch: {
        // watch $projectId to reload project on change
        '$route.params.projectId': {
            handler() {
                this.createProject();
            },
            immediate: true,
        },

        // reset failure on project name change (forward backgword in browser)
        '$route.name': {
            handler() {
                if (this.isProjectLoadingFailed == true || this.isFailed == true)
                    return this.createProject();
            },
            immediate: true,
        },

        isProjectLoadingFailed: {
            handler() {
                if (this.isProjectLoadingFailed == true || this.isFailed == true) {
                    return this.$router.push({ name: 'nirvana' });
                }
            },
            immediate: true,
            deep: true,
        },

        isFailed: {
            handler() {
                if (this.isProjectLoadingFailed == true || this.isFailed == true) {
                    return this.$router.push('nirvana');
                }
            },
            immediate: true,
            deep: true,
        },

        // watch is project loading to add delay on load (smoother loading)
        isProjectLoading: {
            handler() {
                if (this.isProjectLoading == false) {
                    document.title = `${this.project.general.name} - ${this.$product}`;
                    setTimeout(() => {
                        this.loadingProject = false;
                    }, 1000);
                }
            },
            immediate: true,
            deep: true,
        },
    },

    computed: {
        // loading status of project (for splash screen)
        isRedFont() {
            return this.scheduleStatus === scheduleStatusEnum['ARMED'];
        },

        getScheduleStatus() {
            const translated = this.$i18n.t(`${this.scheduleStatus}`);
            return translated;
        },

        isShowBanner() {
            return this.$store.getters.getShowScheduleBanner;
        },

        isProjectLoading() {
            return this.$store.getters.isProjectLoading;
        },

        // indicates if loading project failed
        isProjectLoadingFailed() {
            return this.$store.getters.isProjectLoadingFailed;
        },

        // project data
        project() {
            return this.$store.getters.project;
        },
    },

    beforeCreate() {
        document.title = this.$product;
    },

    created() {
        // check if a selected is specified; if true use specified language
        const langStorage = this.$localStorage.get('lang');
        if (langStorage != null && this.$route.params.lang != langStorage) {
            this.$i18n.locale = langStorage;
            this.$router.replace({ params: { lang: langStorage } });
        }
    },

    destroyed() {
        this.$api.project.destroy(); // destroy project
    },

    methods: {

        openAdmin(id) {
            window.open(`https://admin.alinocam.com/projects?id=${id}`, '_blank')
        },
        // ========================================================================
        // Handling Schedule status
        // ========================================================================
        initialScheduleStatus() {
            // Condition that need from API
            let allowArming = false;
            let allowRecordings = false;

            let status = {};
            let timeBlockFromApi = 0;
            // let schedule = {};

            const timeBlock = {
                UNKNOWN: 0,
                ARMING: 1,
                CAPTURE: 2,
            };

            const projectId = this.project.general.id;

            // =====================================================================
            const scheduleReqResp = Promise.all([
                this.$api.project.getScheduleStatus(projectId),
            ]);
            // =====================================================================


            // ----------------------------------------------------------
            // Need to update some logic for stage
            // ----------------------------------------------------------
            scheduleReqResp.then((res) => {
                // Guarding for api call when get error response
                if (res[0]['error']) {
                    this.showStatusChip = false;
                    this.$store.commit('updateShowScheduleBanner', false);
                    return;
                }

                status = res[0]['data'];

                allowArming = this.project.general['allowArming'];
                allowRecordings = this.project.general['allowRecordings'];

                const isShowBanner = (!status['isSafeguarded'] && allowArming == true);


                if (status['noDevice'] === true && !Object.hasOwn(status, 'type')) {
                    this.updateScheduleStatus('no_devices');
                    this.$store.commit('updateShowScheduleBanner', isShowBanner);
                    this.$store.commit('bannerNoDevices', true);
                }

                if (status['noDevice'] === false && Object.hasOwn(status, 'type')) {
                    timeBlockFromApi = status['type'];

                    if (
                        (allowArming || allowRecordings) &&
                        timeBlockFromApi === timeBlock['UNKNOWN']
                    ) {
                        this.updateScheduleStatus('no_settings');
                        this.$store.commit('updateShowScheduleBanner', isShowBanner);
                    }

                    if (allowArming && timeBlockFromApi === timeBlock['ARMING']) {
                        this.updateScheduleStatus('armed');
                        this.$store.commit('updateShowScheduleBanner', isShowBanner);
                    }

                    if (allowRecordings && timeBlockFromApi === timeBlock['CAPTURE']) {
                        this.updateScheduleStatus('capture');
                        this.$store.commit('updateShowScheduleBanner', isShowBanner);
                    }
                }

            });
        },

        updateScheduleStatus(name) {
            this.scheduleStatus = scheduleStatusEnum[String(name).toUpperCase()];
        },

        switchLanguage(lang) {
            this.$i18n.locale = lang;

            // store new selected language in local storage
            this.$localStorage.set('lang', lang);

            this.$router.replace({ params: { lang: lang } });
            this.languageSelector = false;
        },

        openProject(id) {
            this.$router.push(
                this.$route.fullPath.replace(this.$route.params.projectId, id),
            ); // forward project
        },

        /**
         * createProject
         *
         * Initializes project by requesting required data and updating vuex store
         */
        async createProject() {
            this.projectSelector = false; // close project selector menu
            this.isFailed = false; // reset fail status

            this.$api.project.destroy();

            // set project loading variables
            this.$store.commit('updateProjectLoading', true);
            this.$store.commit('updateProjectSuccess', true);

            this.loadingProject = true;

            // check if current user data is present; if not user data is requested
            var currentUser = this.$store.getters.currentUser;

            // request user data
            if (this.$store.getters.currentUser.meta == null) {
                try {
                    // get user information (meta) and user projects simultanously
                    var userResp = await Promise.all([
                        this.$api.user.user(currentUser.id),
                        this.$api.user.projects(),
                    ]);

                    // check if request was successfull & required data is present --> update vuex store data
                    if (
                        !userResp.some((resp) => resp.success == false) &&
                        userResp[0].data != null &&
                        userResp[1].data != null
                    ) {
                        this.$store.commit('updateCurrentUserMeta', {
                            ...userResp[0].data,
                            internal: userResp[0].data.type == 1,
                            projects: userResp[1].data.projects || [],
                        });
                    }
                } catch (error) {
                    return this.$router.push({
                        name: 'login',
                        query: { type: 'reload', route: this.$route.path, id: 7 },
                    }); // automatic logout
                }
            }

            try {
                // get current project information
                await this.$api.project.information(this.$route.params.projectId);

                const projectHandle = this.$store.getters.project;

                // console.log(projectHandle.isFailed, projectHandle)
                if (projectHandle.isFailed == true || projectHandle.general == null) {
                    return this.$router.push({ name: 'not-found' });
                }

                this.getUsers(projectHandle);

                if (projectHandle.general == null || projectHandle.general == null) {
                    throw new Error();
                } else {
                    if (projectHandle.general.allowRecordings == true) {
                        await this.$api.project.latestImages(this.$route.params.projectId);
                    }
                }
            } catch {
                return this.$router.push({ name: 'not-found' });
            }

            this.$nextTick(() => {
                this.$store.commit('updateProjectLoading', false);
            });

            // -------------------------------------------------------
            // Active method to get Schedule Status of project
            // -------------------------------------------------------
            this.initialScheduleStatus();
        },

        async userData(userObj) {
            const user = await this.$api.user.user(userObj.userId);
            if (user.success == false || user.data == null) return userObj;
            else return { ...userObj, meta: user.data };
        },

        async getUsers(project) {
            this.users = null;

            try {
                if (project.general.members == null)
                    return this.$store.commit('updateProjectUser', []);
                const resp = await Promise.all(
                    project.general.members.map((user) => this.userData(user)),
                );

                const userHandle = this.$store.getters.user;
                if (userHandle != null && userHandle.id != null) {
                    const userObj = resp.reduce((user) => user.id == userHandle.id);

                    if (
                        userHandle.internal != true &&
                        userObj != null &&
                        userObj.type == 10 &&
                        this.$route.name != 'project_dashboard'
                    ) {
                        this.$router.push({ name: 'project_dashboard' }).catch(() => { });
                    }
                }

                this.$store.commit(
                    'updateProjectUser',
                    resp.filter((user) => user.meta != null),
                );
            } catch {
                return;
            }
        },

        errorNavigationEvent() {
            // console.log("e")
            this.isFailed = true;
        },
    },
};
</script>

<style scoped>
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.drawer {
    z-index: 100;
    max-height: 100%;
}

.country-selector-icon {
    position: absolute;
    right: 15px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition: all 50ms linear;
}

.language-selector-list-item {
    background-color: rgba(220, 220, 220, 0);
    border-radius: 10px;
    height: 30px;
    position: relative;
    transition: background-color 150ms linear;
    cursor: pointer;
}

.language-selector-list-item:hover {
    background-color: rgba(220, 220, 220, 0.3);
    transition: background-color 150ms linear;
}

.flag-style {
    border-radius: 10px;
    width: 55px;
    height: 40px;
    padding-bottom: 10px;
    position: absolute;
    top: -5px !important;
    left: 9px !important;
}

.project-selector {
    background-color: rgba(240, 240, 240, 0);
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    height: 36px;
}

.project-selector:hover {
    background-color: rgba(240, 240, 240, 0.6);
}

.list-projects {
    overflow-y: auto;
    overflow-x: hidden !important;
}

.splash-screen {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white !important;
    z-index: 1000 !important;
    user-select: none !important;
    cursor: default !important;
}

.side-bar-img {
    user-select: none;
    user-drag: none !important;
    cursor: pointer;
}

.loading-img {
    pointer-events: none;
    user-select: none;
    user-drag: none;
}

.archive-chip {
    background-color: rgba(220, 220, 220, 1);
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 10px;
    height: 26px;
    border-radius: 13px;
    padding-top: 2px;
    padding-right: 10px;
}

.btn-indicator-status>>>.v-btn {
    border: 1px solid rgb(192, 192, 192);
    border-radius: 20px;
    font-family: 'Lato';
    font-size: 13px;
    text-transform: capitalize;
}

.btn-indicator-status-red>>>.v-btn {
    border: 1px solid rgb(192, 192, 192);
    border-radius: 20px;
    font-family: 'Lato';
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 700;
}

.btn-indicator-status-red>>>.v-btn>.v-btn__content {
    color: var(--v-primary-base);
}

.chip-btn {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 1rem;
}








.nav {
    display: flex;
    flex-flow: column;
    height: 100vh !important;
    position: relative;
    width: 100%;
}



.nav-header {
    flex: 0 1 auto;
    max-height: 48px;
}


.nav-body {
    flex: 1 1 auto;
    max-height: 100%;
}
</style>
