<template>
  <div class="list-navigation" style="">
    <v-list rounded :dark="false" width="230px">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        class="black--text"
      >
        <div v-for="(item, i) in routes" :key="i">
          <v-divider
            class="mt-2 mb-2 mr-4 ml-4"
            v-if="item.meta.dividerTop == true"
          />
          <ListElement
            :alt="routes[0].name"
            :disabled="item.meta.disabled == true || isDashboardOnly || (item.path == 'data-usage' && projectUserRole != 2)"
            :index="i"
            :selected="selectedItem"
            :icon="$t(`${item.meta.i18n}.icon`)"
            :name="$t(`${item.meta.i18n}.title`)"
            :children="item.children"
            :route="item.name"
          />

          
          <!-- {{ item.path == 'data-usage' }} -->
          <v-divider
            class="mt-2 mb-2 mr-4 ml-4"
            v-if="item.meta.dividerBottom == true"
          />
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import ListElement from '@/components/navigation/ListElement.vue';

export default {
  name: 'ListNavigation',
  components: {
    ListElement,
  },

  data() {
    return {
      selectedItem: 0,
      routes: [],
    };
  },

  computed: {
    project() {
      return this.$store.getters.project;
    },
    isProjectLoading() {
      return this.$store.getters.isProjectLoading;
    },


    projectUserRole() {

        const userHandle = this.$store.getters.user;
      if (userHandle.internal == true) return 2;

        const projectUser = this.$store.getters.projectUsers; 
        const projectUserHandle = projectUser.reduce((a, b) => a.userId == userHandle.id ? a : b)

return projectUserHandle.role
    },

    
    isDashboardOnly() {

        // check if admin user
      const userHandle = this.$store.getters.user;
      if (userHandle.internal == true) return false;
      
      // check project user role, whether user is role 10 == isDashboard only
      const projectUser = this.$store.getters.projectUsers; 

      console.log("PROJECT USER ", projectUser)
      if (userHandle == null || projectUser == null || projectUser.length == 0) return true;
      console.log("HE", )

      const projectUserHandle = projectUser.reduce((a, b) => a.userId == userHandle.id ? a : b)

      console.log("kjdnvkjd", this.project)
      return (projectUserHandle == null || projectUserHandle.role == 10)
    },
  },

  watch: {
    '$route.name': {
      handler() {
        if (
          this.isDashboardOnly == true &&
          this.$route.name != 'project_dashboard'
        ) {
          return this.$router.push({name: 'project_dashboard'}).catch(() => {});
        }

        if (this.routes == null || this.routes.length == 0) return;
        return this.matchRoute();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    try {
      const projectRoutesArr = this.getProjectRoutes();

      if (projectRoutesArr == null || projectRoutesArr.length == 0) {
        return this.$emit('error');
      } else this.routes = projectRoutesArr;

      return this.matchRoute();
    } catch (err) {
      return this.$emit('error');
    }
  },

  methods: {
    matchRoute() {
      var resultingIndex = -1;
      this.routes.forEach((route, index) => {
        if (route.name == this.$route.name) {
          resultingIndex = index;
        } else {
          if (route.children != null && route.children.length > 0) {
            var childrenCheck = route.children.findIndex(
              (a) => a.name == this.$route.name,
            );
            if (childrenCheck != -1) resultingIndex = index;
          }
        }
      });

      if (resultingIndex == -1) {
        return this.$emit('error');
      } else {
        if (this.routes[resultingIndex].meta.disabled) {
          this.selectedItem = 0;
        } else this.selectedItem = resultingIndex;
      }
    },

    getProjectRoutes() {
      let projectRoutes = undefined;
      const baseRoutes = this.$router.options.routes.filter(
        (route) => route.path == '/:lang',
      );
      if (baseRoutes == null) throw new Error();

      baseRoutes.forEach((route) => {
        var routeHandle = route.children.find((i) => i.path == 'project');

        if (routeHandle && routeHandle.path == 'project') {
          projectRoutes = routeHandle.children.find(
            (i) => i.path == ':projectId',
          ).children;
        }
      });

      if (!projectRoutes || projectRoutes.length == 0)
        throw new Error('Failure retrieving routes');

      var routesFiltered = projectRoutes.filter(
        (route) =>
          route.meta.required ||
          (route.meta.allowRecordings == true &&
            this.project.general.allowRecordings == true) ||
          (route.meta.allowArming == true &&
            this.project.general.allowArming == true) ||
          (route.meta.allowParkControl == true &&
            this.project.general.allowParkControl == true),
      );

      routesFiltered = routesFiltered.filter(r => !(r.name === 'project_timelapse' && this.project.general.documentationInterval != null && this.project.general.documentationInterval.minutes < 2));

      routesFiltered = routesFiltered.map((route) =>
        route.children == null
          ? route
          : {
              ...route,
              children: route.children.filter(
                (route) =>
                  route.meta.required ||
                  (route.meta.allowRecordings == true &&
                    this.project.general.allowRecordings == true) ||
                  (route.meta.allowArming == true &&
                    this.project.general.allowArming == true) ||
                  (route.meta.allowParkControl == true &&
                    this.project.general.allowParkControl == true),
              ),
            },
      );
      if (
        this.project.allowRecordings == true &&
        this.project.presets == null
      ) {
        routesFiltered = routesFiltered.map((route) => {
          var routeData = route;
          if (route.meta.allowRecordings == true)
            routeData.meta.disabled = true;

          return routeData;
        });
      }

      // if (this.project.general.allowParkControl == true) {
      //   const whitelistData = this.$store.getters.projectWhitelists.data;

      //   var hasViolations = false;

      //   if (whitelistData == null || whitelistData.some(list => list != null && list.allowedTime != null && list.allowedTime > 0)) {
      //     hasViolations = true
      //   }
      //   routesFiltered = routesFiltered.map(route => {
      //     var routeData = route;
      //     if (route.name == 'project_violations') routeData.meta.disabled = !hasViolations;

      //     return routeData
      //     })
      // }

      if (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly) {
        routesFiltered = routesFiltered.filter(
          (route) => !route.meta.hideMobile,
        );
      }

      return routesFiltered;
    },
  },
};
</script>

<style scoped>
.list-navigation {
  overflow-y: auto;
  overflow-x: hidden !important;
}
</style>
